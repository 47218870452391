@font-face {
  font-family: 'FS Me';
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/FSMeWeb-Light.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
  font-family: 'FS Me';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/FSMeWeb-Regular.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
  font-family: 'FS Me';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/FSMeWeb-Bold.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}
@font-face {
  font-family: 'FS Me';
  font-style: normal;
  font-weight: 900;
  src: url(/fonts/FSMeWeb-Ultra.woff2) format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

/*
  Styles for the thin progress bar at top of page that shows during route changes
  Styles were copied from https://github.com/rstacruz/nprogress/blob/v0.2.0/nprogress.css
*/
#nprogress {
  pointer-events: none;
  --color: rgb(160, 213, 211);
}

#nprogress .bar {
  background: var(--color);
  position: fixed;
  z-index: 1231;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--color), 0 0 5px var(--color);
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}
